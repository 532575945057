import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_rfl',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_1',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.your_organisation')
      },
      components: [
        {
          path: 'modules/pages/SectionContainer',
          model: 'logo',
          content: {
            default_wrapper_title: i18n.getLocalesTranslations('literal.logo')
          },
          components: [
            {
              path: 'modules/pages/SectionImageUpload',
              model: 'img',
              settings: {
                hide_label: true,
                crop: false,
                size: { width: 9999, height: 100 }
              }
            },
            {
              path: 'modules/pages/SectionInputUrl',
              model: 'url',
              content: {
                label: i18n.getLocalesTranslations('literal.url')
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator', 'separator__size--full', 'bg__color--vdw']
          }
        },
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.social_networks')
          }
        },
        {
          path: 'modules/pages/SectionNetworks'
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_2',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.organized_by')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionContainer',
          model: 'logo',
          content: {
            default_wrapper_title: i18n.getLocalesTranslations('literal.logo')
          },
          components: [
            {
              path: 'modules/pages/SectionImageUpload',
              model: 'img',
              settings: {
                hide_label: true,
                crop: false,
                size: { width: 9999, height: 100 }
              }
            },
            {
              path: 'modules/pages/SectionInputUrl',
              model: 'url',
              content: {
                label: i18n.getLocalesTranslations('literal.url')
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'social_networks',
          content: {
            label: i18n.getLocalesTranslations('literal.social_networks'),
            btn_label: i18n.getLocalesTranslations('literal.add_social_link'),
            default_wrapper_title: i18n.getLocalesTranslations('literal.social_networks'),
            wrapper_title: 'link.url'
          },
          components: [
            {
              path: 'modules/pages/SectionSelect',
              model: 'network.icon',
              content: {
                label: i18n.getLocalesTranslations('literal.icon')
              },
              settings: {
                options: [
                  { value: 'facebook-square', label: i18n.getLocalesTranslations('Facebook') },
                  { value: 'twitter', label: i18n.getLocalesTranslations('Twitter') },
                  { value: 'x-twitter', label: i18n.getLocalesTranslations('X') },
                  { value: 'instagram', label: i18n.getLocalesTranslations('Instagram') },
                  { value: 'linkedin', label: i18n.getLocalesTranslations('Linkedin') },
                  { value: 'youtube', label: i18n.getLocalesTranslations('Youtube') },
                  { value: 'medium', label: i18n.getLocalesTranslations('Medium') },
                  { value: 'tiktok', label: i18n.getLocalesTranslations('Tiktok') },
                  { value: 'twitch', label: i18n.getLocalesTranslations('Rwitch') }
                ]
              }
            },
            {
              path: 'modules/pages/SectionInputUrl',
              model: 'network.url'
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_3',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.contact')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'address',
          settings: {
            layout: 'inline'
          },
          content: {
            label: i18n.getLocalesTranslations('literal.address')
          },
          components: [
            {
              path: 'modules/pages/SectionInputText',
              model: 'text',
              settings: {
                hide_label: true
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'phone',
          content: {
            label: i18n.getLocalesTranslations('literal.phone')
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'website',
          content: {
            label: i18n.getLocalesTranslations('literal.website')
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'cta',
          content: {
            label: i18n.getLocalesTranslations('literal.call_to_action')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_4',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.footer')
      },
      components: [
        {
          path: 'modules/pages/SectionRepeater',
          model: 'links',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    }
  ]
}
