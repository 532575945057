import i18n from '@/vendors/i18n'
import { projects_overview_options } from '@/configurations/general/components-default-data'

export default [
  {
    name: 'johannesdedoper_kikirpa_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.background')
        },
        model: 'background',
        settings: {
          crop: false,
          size: { width: 1600, height: 9999 }
        }
      },
      {
        path: 'modules/pages/SectionInputUrl',
        content: {
          label: {
            'nl-NL': 'Video url (mp4)',
            'en-GB': 'Video link (mp4)',
            'fr-FR': 'Lien de votre vidéo (mp4)'
          }
        },
        model: 'video'
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'johannesdedoper_kikirpa_quotes',
    settings: {
      icon: 'quote',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'left',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.quote'),
          wrapper_title: 'name'
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 128, height: 128 }
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.quote')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'name',
            content: {
              label: i18n.getLocalesTranslations('literal.name')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionImageUpload',
        model: 'image',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        settings: {
          crop: false,
          size: { width: 1600, height: 9999 }
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'right',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.quote'),
          wrapper_title: 'name'
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 128, height: 128 }
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.quote')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'name',
            content: {
              label: i18n.getLocalesTranslations('literal.name')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.quote')
    }
  },
  {
    name: 'johannesdedoper_kikirpa_stats',
    settings: {
      icon: 'timer',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionSelectCampaigns',
        model: 'campaign_id',
        content: {
          label: {
            'fr-FR': 'Limiter les montants collectés à ces campagnes',
            'en-GB': 'Limit collected amount to those campaigns',
            'nl-NL': 'Limit collected amount to those campaigns'
          }
        }
      },
      {
        path: 'modules/pages/SectionSelectForms',
        model: 'procedure_id',
        content: {
          label: {
            'fr-FR': 'Limiter les montants collectés à ces formulaires',
            'en-GB': 'Limit collected amount to those forms',
            'nl-NL': 'Limit collected amount to those forms'
          }
        }
      },
      {
        path: 'modules/pages/SectionInputAmount',
        model: 'goal',
        content: {
          label: {
            'en-GB': 'Campaign financial goal',
            'fr-FR': 'Campaign financial goal',
            'nl-NL': 'Campaign financial goal'
          }
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'metrics',
        settings: {
          hide_label: true
        },
        content: {
          wrapper_title: 'text',
          btn_label: i18n.getLocalesTranslations('literal.add_metric'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'text'
          },
          {
            path: 'modules/pages/SectionSelect',
            model: 'type',
            value: 'contributors_count',
            settings: {
              options: [
                {
                  value: null,
                  label: i18n.getLocalesTranslations()
                },
                {
                  value: 'contributors_count',
                  label: i18n.getLocalesTranslations('literal.contributors')
                },
                {
                  value: 'contributions_count',
                  label: i18n.getLocalesTranslations('literal.contributions')
                },
                {
                  value: 'amount_collected',
                  label: i18n.getLocalesTranslations('literal.amount_collected')
                },
                {
                  value: 'amount_left_to_collect',
                  label: i18n.getLocalesTranslations('literal.amount_left_to_collect')
                },
                {
                  value: 'goal_amount',
                  label: i18n.getLocalesTranslations('literal.goal_amount')
                },
                {
                  value: 'date',
                  label: i18n.getLocalesTranslations('literal.days_left')
                }
              ]
            },
            content: {
              label: i18n.getLocalesTranslations('literal.metric')
            }
          },
          {
            path: 'modules/pages/SectionInputDate',
            model: 'date',
            content: {
              label: i18n.getLocalesTranslations('literal.date')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'date',
                comparison_operator: 'eq'
              }
            ]
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.cta')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.metrics')
    }
  },
  {
    name: 'johannesdedoper_kikirpa_events',
    settings: {
      icon: 'newsfeed',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        settings: {
          hide_label: true
        },
        content: {
          label: {
            'fr-FR': 'Événements',
            'en-GB': 'Events',
            'nl-NL': 'Events'
          },
          btn_label: {
            'fr-FR': 'Ajouter un événement',
            'en-GB': 'Add an event',
            'nl-NL': 'Add an event',
          },
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 408, height: 228 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          }
        ]
      }
    ],
    content: {
      title: {
        'fr-FR': 'Événements',
        'en-GB': 'Events',
        'nl-NL': 'Events'
      }
    }
  },
  {
    name: 'johannesdedoper_kikirpa_projects',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionSelectCampaigns',
        model: 'query.campaign_id',
        content: {
          label: i18n.getLocalesTranslations('literal.campaigns')
        }
      },
      {
        path: 'modules/pages/SectionSelect',
        model: 'query.sort',
        value: 'created_at_first',
        settings: {
          options: projects_overview_options
        },
        content: {
          label: i18n.getLocalesTranslations('literal.sort_project_by')
        }
      },
      {
        path: 'modules/pages/SectionInputNumber',
        model: 'query.per_page',
        value: 4,
        content: {
          label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.button')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.projects')
    }
  },
  {
    name: 'johannesdedoper_kikirpa_partners',
    settings: {
      icon: 'sponsor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'founding',
        content: {
          default_wrapper_title: {
            'fr-FR': 'Founding Partner',
            'en-GB': 'Founding Partner',
            'nl-NL': 'Founding Partner'
          },
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            },
            settings: {
              configuration: ['bold', 'italic', 'underline', 'align_left', 'align_center', 'align_right']
            }
          },
          {
            path: 'modules/pages/SectionRepeater',
            model: 'images',
            settings: {
              layout: 'inline'
            },
            content: {
              label: {
                'fr-FR': 'Logos',
                'en-GB': 'Logos',
                'nl-NL': 'Logos',
              },
              btn_label: {
                'fr-FR': 'Ajouter un logo',
                'en-GB': 'Add a logo',
                'nl-NL': 'Add a logo',
              },
            },
            components: [
              {
                path: 'modules/pages/SectionImageUpload',
                content: {
                  label: i18n.getLocalesTranslations('literal.logo')
                },
                model: 'logo',
                settings: {
                  hide_label: true,
                  crop: false,
                  size: { width: 270, height: 9999 }
                }
              },
              {
                path: 'modules/pages/SectionInputUrl',
                content: {
                  label: i18n.getLocalesTranslations('literal.link')
                },
                model: 'link'
              }
            ]
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'marketing',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.partners'),
          wrapper_title: 'title'
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionRepeater',
            model: 'images',
            settings: {
              layout: 'inline'
            },
            content: {
              label: {
                'fr-FR': 'Logos',
                'en-GB': 'Logos',
                'nl-NL': 'Logos',
              },
              btn_label: {
                'fr-FR': 'Ajouter un logo',
                'en-GB': 'Add a logo',
                'nl-NL': 'Add a logo',
              }
            },
            components: [
              {
                path: 'modules/pages/SectionImageUpload',
                content: {
                  label: i18n.getLocalesTranslations('literal.logo')
                },
                model: 'logo',
                settings: {
                  hide_label: true,
                  crop: false,
                  size: { width: 270, height: 9999 }
                }
              }
            ]
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.partners')
    }
  }
]