import Procedure from '@/classes/procedures/Procedure'
const resource = 'procedures'

export default {
  namespaced: true,
  state: {
    items: {},
    paginator: {}
  },
  actions: {
    patchProcedure: ({ dispatch }, { params, slug }) => dispatch(
      'patchItem',
      {
        endpoint: `/mykoalect/procedures/${slug}`,
        resource,
        params: {
          procedure: new Procedure(params).database()
        },
        model: procedure => new Procedure(procedure)
      },
      { root: true }
    )
  }
}
