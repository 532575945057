import i18n from '@/vendors/i18n'

export default {
  name: 'how_it_works',
  settings: {
    icon: 'message_cog'
  },
  components: [
    {
      path: 'molecules/ModuleInputTextEditor',
      model: 'attributes.text',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'molecules/ModuleRepeater',
      model: 'attributes.steps',
      settings: {
        layout: 'block'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.steps'),
        btn: i18n.getLocalesTranslations('literal.add_step')
      },
      components: [
        {
          path: 'molecules/ModuleImageUpload',
          model: 'img_src',
          settings: {
            size: { width: 250, height: 250 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'molecules/ModuleInputTextEditor',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.step_description')
          }
        }
      ]
    }
  ],
  style: {
    background: {
      image: i18n.getLocalesTranslations(),
      color: null,
      type: 'color'
    },
    text: {
      color: null
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.how_it_works')
  }
}
