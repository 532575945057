import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_beplanet',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_1',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.logo')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'logo',
          settings: {
            crop: false,
            size: { width: 9999, height: 82 }
          }
        },
        {
          path: 'modules/pages/SectionButton',
          model: 'website',
          content: {
            label: i18n.getLocalesTranslations('literal.website')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_2',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.navigation')
      },
      components: [
        {
          path: 'modules/pages/SectionRepeater',
          model: 'links',
          settings: {
            layout: 'inline',
            hide_label: true
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_3',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.sponsors')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'sponsors',
          settings: {
            hide_label: true
          },
          content: {
            wrapper_title: 'link',
            btn_label: i18n.getLocalesTranslations('literal.add_new_sponsor')
          },
          components: [
            {
              path: 'modules/pages/SectionImageUpload',
              model: 'logo',
              settings: {
                crop: false,
                size: { width: 9999, height: 82 }
              }
            },
            {
              path: 'modules/pages/SectionInputUrl',
              model: 'link'
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_4',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.actions')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'cta',
          content: {
            label: i18n.getLocalesTranslations('literal.call_to_action')
          }
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
          }
        },
        {
          path: 'modules/pages/SectionNetworks'
        }
      ]
    }
  ]
}