export default [
  {
    key: 'benefitings_what_is_benefiting'
  },
  {
    key: 'benefitings_how_to_add_new_beneficiaries'
  },
  {
    key: 'benefitings_how_to_integrate_beneficiaries_in_my_campaign'
  }
]
