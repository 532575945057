import i18n from '@/vendors/i18n'
import { metrics_type_options, metrics_sporting_options } from '@/configurations/general/components-default-data'

export default {
  name: 'counter',
  settings: {
    icon: 'timer'
  },
  components: [
    {
      path: 'molecules/ModuleInputTextEditor',
      model: 'attributes.text',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'molecules/ModuleInputSelectCampaign',
      model: 'attributes.query.campaign_id',
      content: {
        label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
      }
    },
    {
      path: 'molecules/ModuleInputSelectProcedure',
      model: 'attributes.query.procedure_id',
      content: {
        label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
      }
    },
    {
      path: 'molecules/ModuleInputSelectBenefiting',
      model: 'attributes.query.benefiting_id',
      content: {
        label: i18n.getLocalesTranslations('literal.show_metrics_from_these_beneficiaries')
      }
    },
    {
      path: 'molecules/ModuleRepeater',
      model: 'attributes.metrics',
      settings: {
        layout: 'block'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.metrics'),
        wrapper_title: 'text',
        btn: i18n.getLocalesTranslations('literal.add_metric'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
      },
      components: [
        {
          path: 'molecules/ModuleInputSelect',
          model: 'type',
          value: 'contributors_count',
          options: metrics_type_options,
          content: {
            label: i18n.getLocalesTranslations('literal.metric'),
            placeholder: i18n.getLocalesTranslations('literal.select_a_metric')
          }
        },
        {
          path: 'molecules/ModuleInputDatepicker',
          model: 'date',
          settings: {
            show_time: true
          },
          content: {
            label: i18n.getLocalesTranslations('literal.date')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'date',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'molecules/ModuleInputSelect',
          model: 'sporting_statistics',
          value: 'total_distance',
          options: metrics_sporting_options,
          content: {
            label: i18n.getLocalesTranslations('literal.sporting_statistics')
          },
          conditions: [
            {
              logic_operator: 'and',
              model: 'type',
              value: 'sporting_statistics',
              comparison_operator: 'eq'
            }
          ]
        },
        {
          path: 'molecules/ModuleInputText',
          model: 'text',
          content: {
            label: i18n.getLocalesTranslations('literal.text')
          }
        }
      ]
    }
  ],
  style: {
    background: {
      image: i18n.getLocalesTranslations(),
      color: null,
      type: 'color'
    },
    text: {
      color: null
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.counter')
  }
}
