import i18n from '@/vendors/i18n'

export default {
  name: 'banner',
  settings: {
    icon: 'banner'
  },
  components: [
    {
      path: 'molecules/ModuleInputTextEditor',
      model: 'attributes.title',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link', 'align_left', 'align_center', 'align_right', 'text_color']
      },
      style: {
        text_editor: ['editor__banner']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'molecules/ModuleInputTextEditor',
      model: 'attributes.text',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'molecules/ModuleInputLink',
      model: 'attributes.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    }
  ],
  style: {
    background: {
      image: i18n.getLocalesTranslations(),
      color: null,
      type: 'color'
    },
    text: {
      color: null
    },
    button: {
      color: null,
      background: null
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.banner')
  }
}
