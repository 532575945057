import { nextTick, watch } from 'vue'
import { merge as _merge, get as _get, cloneDeep as _cloneDeep } from 'lodash'
import queryString from 'query-string'

const countObjectProperties = (obj) => {
  if (typeof obj === 'object') {
    return Object.keys(obj).length
  }
  return 0
}

const removeEmptyProperties = (obj) => {
  const objCopy = { ...obj }
  Object.keys(objCopy).forEach(key => {
    if (objCopy[key] && typeof objCopy[key] === 'object') removeEmptyProperties(objCopy[key])
    else if (objCopy[key] == null) delete objCopy[key]
  })
  return objCopy
}

const isOdd = (number) => {
  return number % 2 === 1
}

const isEven = (number) => {
  return number % 2 === 0
}

const slugify = text => {
  text = text.toString().toLowerCase().trim()

  const sets = [
    { to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
    { to: 'c', from: '[ÇĆĈČ]' },
    { to: 'd', from: '[ÐĎĐÞ]' },
    { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
    { to: 'g', from: '[ĜĞĢǴ]' },
    { to: 'h', from: '[ĤḦ]' },
    { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
    { to: 'j', from: '[Ĵ]' },
    { to: 'ij', from: '[Ĳ]' },
    { to: 'k', from: '[Ķ]' },
    { to: 'l', from: '[ĹĻĽŁ]' },
    { to: 'm', from: '[Ḿ]' },
    { to: 'n', from: '[ÑŃŅŇ]' },
    { to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
    { to: 'oe', from: '[Œ]' },
    { to: 'p', from: '[ṕ]' },
    { to: 'r', from: '[ŔŖŘ]' },
    { to: 's', from: '[ßŚŜŞŠ]' },
    { to: 't', from: '[ŢŤ]' },
    { to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
    { to: 'w', from: '[ẂŴẀẄ]' },
    { to: 'x', from: '[ẍ]' },
    { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
    { to: 'z', from: '[ŹŻŽ]' },
    { to: '-', from: '[·/_,:;\']' }
  ]

  sets.forEach(set => {
    text = text.replace(new RegExp(set.from, 'gi'), set.to)
  })

  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/&/g, '-and-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

const clone = obj => _cloneDeep(obj)

const arrayMove = (arr, oldIndex, newIndex) => {
  while (oldIndex < 0) {
    oldIndex += arr.length
  }

  while (newIndex < 0) {
    newIndex += arr.length
  }

  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }

  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
}

const guid = (val) => {
  const num = parseInt(val) || 8
  let result = '_'

  function s4 () {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }

  for (let i = 0; i < num; i++) {
    result += s4()
  }

  return result
}

const truncate = (string, n) => {
  if (string) {
    if (string.length <= n) {
      return string
    }
    let subString = string.substring(0, n - 1)
    return subString.substring(0, subString.lastIndexOf(' ')) + '...'
  } else {
    return ''
  }
}

const toCamelCase = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '')
  })
}

const toSnakeCase = str => {
  return str ? str.replace(/[^a-zA-Z0-9]+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_') : null
}

const extractNumbers = object => {
  const obj = /-(\d*)/g.exec(object)
  const index = obj ? obj[1] : null
  return parseInt(index)
}

const getImagePath = (path) => {
  return `https://s3.eu-west-3.amazonaws.com/koalect-images/${path}`
}

const hexToRGB = (hex) => {
  const match = hex.toString(16).match(/[a-f0-9]{6}|[a-f0-9]{3}/i)

  if (!match) {
    return [0, 0, 0]
  }

  let colorString = match[0]

  if (match[0].length === 3) {
    colorString = colorString.split('').map(char => {
      return char + char
    }).join('')
  }

  const integer = parseInt(colorString, 16)
  const r = (integer >> 16) & 0xFF
  const g = (integer >> 8) & 0xFF
  const b = integer & 0xFF

  return {
    r,
    g,
    b
  }
}

const rgbToHSL = (value) => {
  const r = value.r /= 255
  const g = value.g /= 255
  const b = value.b /= 255
  const min = Math.min(r, g, b)
  const max = Math.max(r, g, b)
  const delta = max - min
  let h
  let s

  if (max === min) {
    h = 0
  } else if (r === max) {
    h = (g - b) / delta
  } else if (g === max) {
    h = 2 + (b - r) / delta
  } else if (b === max) {
    h = 4 + (r - g) / delta
  }

  h = Math.min(h * 60, 360);

  if (h < 0) {
    h += 360
  }

  const l = (min + max) / 2

  if (max === min) {
    s = 0
  } else if (l <= 0.5) {
    s = delta / (max + min)
  } else {
    s = delta / (2 - max - min)
  }

  return {
    h: Math.round(h),
    s: Math.round(s * 100),
    l: Math.round(l * 100)
  }
}

const rgbToHex = (r, g, b) => {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
}

const filterObject = (obj, predicate) => {
  return Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce((res, key) => (res[key] = obj[key], res), {})
}

const gcd = (a, b) => {
  if (b > a) {
    const temp = a
    a = b
    b = temp
  }

  while (b != 0) {
    const m = a % b
    a = b
    b = m
  }
  return a
}

const operators = {
  eq: (a, b) => a === b,
  neq: (a, b) => a !== b,
  gt: (a, b) => a > b,
  gtoeq: (a, b) => a >= b,
  ls: (a, b) => a < b,
  lsoeq: (a, b) => a <= b,
  and: (a, b) => a && b,
  or: (a, b) => a || b,
  empty: (a) => a === null || a === undefined || a === '',
  nempty: (a) => a !== null && a !== undefined && a !== '',
  checked: (a) => a === true,
  unchecked: (a) => a === false,
  text: (a) => a.replace(/\s+/g, '').toLowerCase(),
  select: (a) => a.replace(/\s+/g, '').toLowerCase(),
  number: (a) => parseInt(a),
  amount: (a) => parseInt(a)
}

const copyToClipboard = (text, target = document.body) => {
  return new Promise((resolve, reject) => {
    const field = document.createElement('input')

    field.value = text

    target.appendChild(field)

    field.focus()
    field.select()
    field.setSelectionRange(0, 99999) // mobile

    try {
      document.execCommand('copy')
      resolve()
    } catch (err) {
      reject(err)
    }

    target.removeChild(field)
  })
}

const scrollTo = selector => {
  nextTick(() => {
    let position = 0

    if (selector) {
      const elm = document.querySelector(selector)

      if (elm) position = elm.getBoundingClientRect().top + document.documentElement.scrollTop - 150
    }

    window.scrollTo({ top: position, behavior: 'smooth' })
  })
}

const getStringifyQuery = ({ query, default_value = null }) => {
  return query ? `?${queryString.stringify(query, { arrayFormat: 'bracket' })}` : default_value
}

const isDefined = value => {
  if (!value) return false
  if (typeof value === 'object' && !countObjectProperties(value)) return false
  if (Array.isArray(value) && !value.length) return false

  return true
}

const isCertified = ({ attributes = {}, models = {} }) => {
  if (!attributes || !Object.keys(attributes).length) return true

  return Object.keys(attributes).every(key => {
    const model = _get(models, key.split('.')[0])
    const value = _get(model, key.split('.').slice(1).join('.'), '')

    if (typeof value === 'string') return attributes[key].includes(value) || attributes[key].some(regex => !!value.match(new RegExp(regex, 'g')))
    if (typeof value === 'object') return attributes[key].some(key => Object.keys(value).includes(key))
    if (typeof value === 'boolean') return attributes[key] === !!value

    return true
  })
}

const formatBytes = (bytes, decimal = 0, k = 1024) => {
  if (bytes == 0) return '0 b'

  const sizes = ['b', 'kb', 'mb', 'gb']
  const i = Math.floor(Math.log(bytes) / Math.log(1024))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimal)) + ' ' + sizes[i]
}

const $cookies = {
  get: name => {
    let cookie = document.cookie.split('; ').find(cookie => cookie.includes(name))

    return cookie ? (cookie.split('=')[1] || '') : false
  },

  set: ({ name, value, path = '/', expire = (6 * 30 * 24 * 3600) }) => {
    document.cookie = `${name}=${value}; path=${path}; max-age=${expire}`
  },

  delete: ({ name, path = '/' }) => {
    document.cookie = `${name}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  }
}

const $sessionStorage = {
  get: name => {
    return JSON.parse(sessionStorage.getItem(name))
  },
  set: ({ name, data, merge = false }) => {
    const store = $sessionStorage.get(name)
    const item = store && merge ? _merge({}, store, data) : data

    sessionStorage.setItem(name, JSON.stringify(item))
  },
  delete: name => {
    sessionStorage.removeItem(name)
  }
}

const stripTags = (str = '') => str.replace( /(<([^>]+)>)/ig, '')

const whenever = (source, callback, options) => {
  return watch(
    source,
    (value, oldValue, onInvalidate) => {
      if (value) callback(value, oldValue, onInvalidate)
    },
    options
  )
}

export {
  countObjectProperties,
  removeEmptyProperties,
  isOdd,
  isEven,
  slugify,
  clone,
  arrayMove,
  guid,
  truncate,
  toCamelCase,
  toSnakeCase,
  extractNumbers,
  getImagePath,
  hexToRGB,
  rgbToHSL,
  rgbToHex,
  filterObject,
  gcd,
  operators,
  copyToClipboard,
  scrollTo,
  getStringifyQuery,
  isDefined,
  isCertified,
  formatBytes,
  stripTags,
  whenever,
  $cookies,
  $sessionStorage
}
