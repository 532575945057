import i18n from '@/vendors/i18n'

export default {
  name: 'default_footer',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'molecules/ModuleDivider'
    },
    {
      path: 'molecules/ModuleTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.column_2.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'molecules/ModuleRepeater',
      model: 'attributes.column_2.navigation',
      content: {
        label: i18n.getLocalesTranslations('literal.links'),
        btn: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'molecules/ModuleInputLink',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'molecules/ModuleDivider'
    },
    {
      path: 'molecules/ModuleTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.actions')
      }
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.column_3.title',
      content: {
        label: i18n.getLocalesTranslations('literal.title')
      }
    },
    {
      path: 'molecules/ModuleRepeater',
      model: 'attributes.column_3.actions',
      content: {
        label: i18n.getLocalesTranslations('literal.links'),
        btn: i18n.getLocalesTranslations('literal.add_new_button')
      },
      components: [
        {
          path: 'molecules/ModuleInputLink',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'molecules/ModuleDivider'
    },
    {
      path: 'molecules/ModuleTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.social_networks')
      }
    },
    {
      path: 'modules/pages/SectionNetworks'
    }
  ]
}
