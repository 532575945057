import i18n from '@/vendors/i18n'
import { metrics_type_options, metrics_sporting_options, projects_overview_options } from '@/configurations/general/components-default-data'

export default [
  {
    name: 'benefiting_beplanet_settings',
    settings: {
      icon: 'form',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionSelectProjects',
        model: 'project_id',
        content: {
          label: i18n.getLocalesTranslations('literal.project')
        },
        settings: {
          limit: 1
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.settings')
    }
  },
  {
    name: 'benefiting_beplanet_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'benefiting_beplanet_stats',
    settings: {
      icon: 'timer',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionRepeater',
        model: 'metrics',
        content: {
          wrapper_title: 'text',
          btn_label: i18n.getLocalesTranslations('literal.add_metric'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'type',
            value: 'contributors_count',
            settings: {
              options: [...metrics_type_options, { value: 'custom', label: i18n.getLocalesTranslations('literal.custom') }]
            },
            content: {
              label: i18n.getLocalesTranslations('literal.metric')
            }
          },
          {
            path: 'modules/pages/SectionInputDate',
            model: 'date',
            content: {
              label: i18n.getLocalesTranslations('literal.date')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'date',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionSelect',
            model: 'sporting_statistics',
            value: 'total_distance',
            settings: {
              options: metrics_sporting_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sporting_statistics')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'sporting_statistics',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'value',
            content: {
              label: i18n.getLocalesTranslations('literal.value')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'custom',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'text'
          }
        ]
      },
      {
        path: 'modules/pages/SectionDivider',
        style: {
          divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'links',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.buttons')
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'project',
            content: {
              label: i18n.getLocalesTranslations('literal.project')
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'campaigns',
            content: {
              label: i18n.getLocalesTranslations('literal.campaigns')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.counter')
    }
  },
  {
    name: 'benefiting_beplanet_about',
    settings: {
      icon: 'text_editor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image',
        settings: {
          crop: true,
          size: { width: 540, height: 477 }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.about')
    }
  },
  {
    name: 'benefiting_beplanet_actions',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'sort',
            value: 'created_at_first',
            settings: {
              options: projects_overview_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sort_project_by')
            }
          },
          {
            path: 'modules/pages/SectionInputNumber',
            model: 'per_page',
            value: 4,
            content: {
              label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
            }
          },
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.campaigns')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.projects')
    }
  },
  {
    name: 'benefiting_beplanet_partners',
    settings: {
      icon: 'sponsor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'sponsors',
        settings: {
          hide_label: true
        },
        content: {
          wrapper_title: 'link',
          btn_label: i18n.getLocalesTranslations('literal.add_new_sponsor')
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'logo',
            settings: {
              crop: false,
              size: { width: 9999, height: 90 }
            }
          },
          {
            path: 'modules/pages/SectionInputUrl',
            model: 'link'
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.sponsors')
    }
  }
]