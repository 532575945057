import i18n from '@/vendors/i18n'
import Sections from '@/classes/pages/Sections'
import { get as _get, omit as _omit } from 'lodash'
import { clone } from '@/utils/utils'

export default class Page {
  constructor ({ id = null, title = i18n.getLocalesTranslations(), description = i18n.getLocalesTranslations(), image = {}, remote_image_url = null, status = null, category = null, display_mode = null, main = false, reserved = false, options = {}, path = null, sections, created_at = null } = {}) {
    this.id = id
    this.title = title
    this.description = description
    this.image = image
    this.remote_image_url = remote_image_url
    this.status = status
    this.category = category
    this.display_mode = display_mode
    this.main = main
    this.reserved = reserved
    this.options = options
    this.path = path
    this.sections = new Sections(sections)
    this.created_at = created_at
  }

  get is_draft () {
    return this.status === 'draft'
  }

  get allow_new_sections () {
    return !_get(this.options, 'hide_section_addition', false)
  }

  get allow_delete_page () {
    return !_get(this.options, 'hide_page_deletion', false)
  }

  get preview_status () {
    return this.status === 'published' ? 'live' : 'draft'
  }

  get show_header () {
    return !this.sections.header?.is_not_editable && this.sections.header?.components.length
  }

  get show_footer () {
    return !this.sections.footer?.is_not_editable && this.sections.footer?.components.length
  }

  get to_unsaved () {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      image: this.image,
      remote_image_url: this.remote_image_url,
      status: this.status,
      sections: clone(this.sections.to_database).map(section => _omit(section, 'options.collapse'))
    }
  }

  get to_database () {
    const ommited_keys = []

    if (!this.remote_image_url) ommited_keys.push('remote_image_url')

    return _omit({
      id: this.id,
      title: this.title,
      description: this.description,
      image: this.image,
      remote_image_url: this.remote_image_url,
      status: this.status,
      sections: this.sections.to_database
    }, ommited_keys)
  }
}
