export default [
  {
    key: 'campaigns_how_to_get_started'
  },
  {
    key: 'campaigns_how_to_manage_your_campaign_settings'
  },
  {
    key: 'campaigns_how_to_enable_strava_in_your_campaigns',
    is_allowed: { 'route.query.tab': ['sporting-activities'] }
  },
  {
    key: 'campaigns_what_are_the_forms'
  },
  {
    key: 'campaigns_how_can_i_build_a_form'
  },
  {
    key: 'campaigns_what_are_products'
  },
  {
    key: 'campaigns_how_can_i_use_products_in_my_campaign'
  },
  {
    key: 'campaigns_how_can_i_setup_the_project_page'
  },
  {
    key: 'campaigns_what_are_the_qr_codes_with_payconiq',
    is_allowed: { 'route.query.tab': ['payconiq'] }
  },
  {
    key: 'campaigns_how_to_use_the_payconiq_qr_codes',
    is_allowed: { 'route.query.tab': ['payconiq'] }
  },
  {
    key: 'campaigns_additional_link'
  },
  {
    key: 'campaigns_advanced_exports'
  },
  {
    key: 'campaigns_introduction_to_templates',
    is_allowed: { 'route.query.tab': ['template'] }
  },
  {
    key: 'campaigns_how_to_create_a_template',
    is_allowed: { 'route.query.tab': ['template'] }
  },
  {
    key: 'campaigns_how_do_the_new_templates_affect_existing_campaigns_and_projects',
    is_allowed: { 'route.query.tab': ['template'] }
  },
  {
    key: 'campaigns_what_is_project_validation',
    is_allowed: { 'route.query.tab': ['validation'] }
  },
  {
    key: 'campaigns_does_my_campaign_need_project_validation',
    is_allowed: { 'route.query.tab': ['validation'] }
  },
  {
    key: 'campaigns_how_to_enable_project_validation',
    is_allowed: { 'route.query.tab': ['validation'] }
  },
  {
    key: 'campaigns_set_up_automatic_mails_for_your_campaign'
  }
]
