import i18n from '@/vendors/i18n'

export default {
  name: 'sponsors',
  settings: {
    icon: 'sponsor'
  },
  components: [
    {
      path: 'molecules/ModuleInputTextEditor',
      model: 'attributes.text',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'molecules/ModuleRepeater',
      model: 'attributes.sponsors',
      settings: {
        layout: 'block'
      },
      content: {
        label: i18n.getLocalesTranslations('literal.sponsors'),
        wrapper_title: 'name',
        btn: i18n.getLocalesTranslations('literal.add_new_sponsor'),
        default_wrapper_title: i18n.getLocalesTranslations('literal.sponsors')
      },
      components: [
        {
          path: 'molecules/ModuleImageUpload',
          model: 'logo',
          settings: {
            crop: false,
            size: { width: 400, height: 400 }
          },
          content: {
            label: i18n.getLocalesTranslations('literal.image')
          }
        },
        {
          path: 'molecules/ModuleInputText',
          model: 'name',
          content: {
            label: i18n.getLocalesTranslations('literal.sponsor_name')
          }
        },
        {
          path: 'molecules/ModuleInputUrl',
          model: 'website',
          validations: ['url'],
          content: {
            label: i18n.getLocalesTranslations('literal.website_link')
          }
        }
      ]
    }
  ],
  style: {
    background: {
      image: i18n.getLocalesTranslations(),
      color: null,
      type: 'color'
    },
    text: {
      color: null
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.sponsors')
  }
}
