import i18n from '@/vendors/i18n'

export default {
  name: 'project_benefiting',
  model: 'actions_data.project.benefiting',
  type: 'input_text',
  settings: {
    icon: 'love_hand',
    category: 'project_fields',
    require_model: true,
    faq: 'procedures_what_is_benefiting'
  },
  content: {
    title: i18n.getLocalesTranslations('$procedure_block.name_id_project_benefiting')
  },
  components: [
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.label',
      value: i18n.getLocalesTranslations('$block.project_benefiting_label'),
      content: {
        label: i18n.getLocalesTranslations('literal.question')
      }
    },
    { path: 'molecules/ModuleDivider' },
    { path: 'modules/procedures/BlockBenefiting' },
    {
      path: 'molecules/ModuleInputCheckboxMandatory',
      value: 'required',
      content: {
        label: i18n.getLocalesTranslations('$procedure_block.label_is_mandatory')
      }
    }
  ],
  is_allowed: {
    'procedure.actions': ['project'],
    'procedure.category': ['create-project'],
    'client.allowed_features': ['benefitings']
  }
}
