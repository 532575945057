import i18n from '@/vendors/i18n'

export default {
  name: 'default_header',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionLogo'
    },
    {
      path: 'molecules/ModuleDivider'
    },
    {
      path: 'molecules/ModuleTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.navigation')
      }
    },
    {
      path: 'molecules/ModuleRepeater',
      model: 'attributes.navigation',
      content: {
        btn: i18n.getLocalesTranslations('literal.add_new_link')
      },
      components: [
        {
          path: 'molecules/ModuleInputLink',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'molecules/ModuleDivider'
    },
    {
      path: 'molecules/ModuleTitle',
      content: {
        label: i18n.getLocalesTranslations('literal.buttons')
      }
    },
    {
      path: 'molecules/ModuleRepeater',
      model: 'attributes.actions',
      content: {
        btn: i18n.getLocalesTranslations('literal.add_new_button')
      },
      components: [
        {
          path: 'molecules/ModuleInputLink',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    },
    {
      path: 'molecules/ModuleDivider'
    },
    {
      path: 'molecules/ModuleText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.login_register_and_profile')
      }
    },
    {
      path: 'molecules/ModuleDivider'
    },
    {
      path: 'molecules/ModuleText',
      style: {
        text: ['color--lg']
      },
      content: {
        text: i18n.getLocalesTranslations('literal.language_switch')
      }
    }
  ]
}
