export default [
  {
    key: 'templates_introduction_to_templates'
  },
  {
    key: 'templates_how_to_create_a_template'
  },
  {
    key: 'templates_how_do_the_new_templates_affect_existing_campaigns_and_projects'
  }
]
