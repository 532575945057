import i18n from '@/vendors/i18n'

export default {
  name: 'quote',
  settings: {
    icon: 'quote'
  },
  components: [
    {
      path: 'molecules/ModuleInputTextEditor',
      model: 'attributes.text_editor',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'molecules/ModuleInputTextEditor',
      model: 'attributes.quote',
      settings: {
        configuration: ['bold', 'italic', 'underline', 'link', 'text_color']
      },
      content: {
        label: i18n.getLocalesTranslations('literal.quote')
      }
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.author_name',
      content: {
        label: i18n.getLocalesTranslations('literal.author_name')
      }
    },
    {
      path: 'molecules/ModuleInputText',
      model: 'attributes.author_job',
      content: {
        label: i18n.getLocalesTranslations('literal.author_job_title')
      }
    },
    {
      path: 'molecules/ModuleImageUpload',
      model: 'attributes.image',
      settings: {
        size: { width: 100, height: 100 }
      },
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      }
    }
  ],
  style: {
    background: {
      image: i18n.getLocalesTranslations(),
      color: null,
      type: 'color'
    },
    text: {
      color: null
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.quote')
  }
}
