import i18n from '@/vendors/i18n'

export default {
  name: 'campaigns',
  settings: {
    icon: 'flag'
  },
  components: [
    {
      path: 'molecules/ModuleInputTextEditor',
      model: 'attributes.title',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'molecules/ModuleSelectCampaigns',
      model: 'attributes.campaigns.ids',
      content: {
        label: i18n.getLocalesTranslations('literal.campaigns')
      }
    },
    {
      path: 'molecules/ModuleDivider'
    },
    {
      path: 'molecules/ModuleInputSwitch',
      model: 'attributes.campaigns.highlighted',
      content: {
        label: i18n.getLocalesTranslations('literal.highlight_these_campaigns')
      }
    }
  ],
  style: {
    background: {
      image: i18n.getLocalesTranslations(),
      color: null,
      type: 'color'
    },
    text: {
      color: null
    }
  },
  content: {
    title: i18n.getLocalesTranslations('literal.campaigns')
  }
}
