import { defineStore } from 'pinia'
import { pick as _pick } from 'lodash'
import Page from '@/classes/pages/Page'

export const usePageStore = defineStore('PageStore', {
  state: () => ({
    pages: {},
    page: {},
    layout: {},
    loader: false,
    error: {}
  }),
  getters: {
    getLayout: state => () => {
      return state.$getItem({
        resource: 'layout',
        fetch: () => state.fetchMainLayout()
      })
    },
    getPages: state => ({ query, dependencies }) => {
      return state.$getItems({
        resource: 'pages',
        query,
        dependencies,
        fetch: query => state.fetchPages({ query })
      })
    },
    getPage: state => ({ id }) => {
      return state.$getItem({
        resource: 'page',
        key: id,
        fetch: id => state.fetchPage({ id })
      })
    }
  },
  actions: {
    fetchMainLayout () {
      return this.$fetchItem({
        endpoint: '/mykoalect/pages/main_layout',
        resource: 'layout',
        model: Page
      })
    },
    fetchPages ({ query }) {
      return this.$fetchItems({
        endpoint: '/mykoalect/pages',
        resource: 'pages',
        model: Page,
        query
      })
    },
    fetchPage ({ id }) {
      return this.$fetchItem({
        endpoint: `/mykoalect/pages/${id}`,
        resource: `page.${id}`,
        model: Page
      })
    },
    postPage ({ params }) {
      return this.$postItem({
        endpoint: '/mykoalect/pages',
        resource: 'page.{id}',
        model: Page,
        params: {
          page: new Page(params)
        }
      })
    },
    patchMainLayout ({ params, id }) {
      return this.$patchItem({
        endpoint: `/mykoalect/pages/${id}`,
        resource: 'layout',
        model: Page,
        params: {
          page: _pick(new Page(params), Object.keys(params))
        }
      })
    },
    patchPage ({ params, id }) {
      return this.$patchItem({
        endpoint: `/mykoalect/pages/${id}`,
        resource: `page.${id}`,
        model: Page,
        params: {
          page: _pick(new Page(params), Object.keys(params))
        }
      })
    },
    deletePage ({ id }) {
      return this.$deleteItem({
        endpoint: `/mykoalect/pages/${id}`,
        resource: id
      })
    }
  }
})