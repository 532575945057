import i18n from '@/vendors/i18n'

export default class Link {
  constructor ({ show, url = i18n.getLocalesTranslations(), icon = null, text = i18n.getLocalesTranslations(), target_url = null, type = null } = {}) {
    this.show = typeof show == 'boolean' ? show : true
    this.url = url
    this.icon = icon
    this.text = text
    this.target_url = target_url
    this.type = type
  }

  get has_text () {
    return Object.values(this.text).some(text => text)
  }

  get is_custom () {
    return this.type === 'custom'
  }

  get is_campaign () {
    return this.type === 'campaign'
  }

  get is_procedure () {
    return this.type === 'form'
  }

  get is_page () {
    return this.type === 'page'
  }
}
