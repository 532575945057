import i18n from '@/vendors/i18n'

export default {
  name: 'custom_header_uzafoundation',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'top_nav',
      content: {
        default_wrapper_title: {
          'en-GB': 'Top navigation',
          'fr-FR': 'Navigation supérieure',
          'nl-NL': 'Navigatie bovenaan'
        }
      },
      components: [
        {
          path: 'modules/pages/SectionRepeater',
          model: 'links',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator','separator__size--full', 'bg__color--vdw']
          }
        },
        {
          path: 'modules/pages/SectionText',
          style: {
            text: ['color--lg']
          },
          content: {
            text: i18n.getLocalesTranslations('literal.language_switch')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'logo',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.logo')
      },
      components: [
        {
          path: 'modules/pages/SectionImageUpload',
          model: 'img',
          settings: {
            hide_label: true,
            crop: false,
            size: { width: 9999, height: 30 }
          }
        },
        {
          path: 'modules/pages/SectionInputUrl',
          model: 'url',
          content: {
            label: i18n.getLocalesTranslations('literal.url')
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'nav',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.navigation')
      },
      components: [
        {
          path: 'modules/pages/SectionRepeater',
          model: 'links',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            },
            {
              path: 'modules/pages/SectionRepeater',
              model: 'links',
              settings: {
                hide_label: true,
                layout: 'inline'
              },
              content: {
                btn_label: i18n.getLocalesTranslations('literal.add_new_link')
              },
              components: [
                {
                  path: 'modules/pages/SectionButton',
                  model: 'link',
                  settings: {
                    hide_label: true
                  }
                },
                {
                  path: 'modules/pages/SectionRepeater',
                  model: 'links',
                  settings: {
                    hide_label: true,
                    layout: 'inline'
                  },
                  content: {
                    btn_label: i18n.getLocalesTranslations('literal.add_new_link')
                  },
                  components: [
                    {
                      path: 'modules/pages/SectionButton',
                      model: 'link',
                      settings: {
                        hide_label: true
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'search',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.search')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'label',
          content: {
            label: i18n.getLocalesTranslations('literal.label')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'placeholder',
          content: {
            label: i18n.getLocalesTranslations('literal.placeholder')
          }
        },
        {
          path: 'modules/pages/SectionInputUrl',
          model: 'url',
          content: {
            label: i18n.getLocalesTranslations('literal.url')
          }
        },
        {
          path: 'modules/pages/SectionInputText',
          model: 'query',
          content: {
            label: {
              'en-GB': 'URL param',
              'fr-FR': 'Paramètre de l\'URL',
              'nl-NL': 'URL-parameter'
            }
          }
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'cta',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.call_to_action')
      },
      components: [
        {
          path: 'modules/pages/SectionButton',
          model: 'link',
          settings: {
            hide_label: true
          }
        }
      ]
    }
  ]
}