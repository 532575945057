import Procedures from '@/classes/procedures/Procedures'
import Link from '@/classes/Link'
import { isPast } from 'date-fns'
import { get as _get } from 'lodash'
import { getImagePath } from '@/utils/utils'

export default class Campaign {
  constructor ({ id = null, name = {}, cta, learn_more, content = {}, image = null, remote_image_url = null, description = {}, procedures, emails = [], slug = null, status = null, end_date = null, start_date = null, projects = [], projects_count = 0, projects_collected_amount = 0, projects_contributor_count = 0, created_at = null, project_display_settings = {}, goal_amount_per_member = 0, limit_of_members = 0, limit_of_projects = 0, sporting_activities_settings = {}, settings = {}, template_id = null } = {}) {
    this.id = id
    this.name = name
    this.cta = new Link(cta)
    this.learn_more = new Link(learn_more)
    this.content = content
    this.image = image
    this.remote_image_url = remote_image_url
    this.description = description
    this.procedures = new Procedures(procedures)
    this.emails = emails
    this.slug = slug
    this.status = status
    this.end_date = end_date
    this.start_date = start_date
    this.projects = projects
    this.projects_count = projects_count
    this.projects_collected_amount = projects_collected_amount
    this.projects_contributor_count = projects_contributor_count
    this.created_at = created_at
    this.project_display_settings = project_display_settings
    this.goal_amount_per_member = goal_amount_per_member
    this.limit_of_members = limit_of_members
    this.limit_of_projects = limit_of_projects
    this.sporting_activities_settings = sporting_activities_settings
    this.settings = settings
    this.template_id = template_id
  }

  get is_live () {
    return this.status === 'published'
  }

  get is_draft () {
    return this.status === 'draft'
  }

  get is_started () {
    return this.start_date ? isPast(new Date(this.start_date)) : false
  }

  get is_ended () {
    return this.end_date ? isPast(new Date(this.end_date)) : false
  }

  get is_active () {
    return this.is_started && !this.is_ended
  }

  get cover () {
    return _get(this, 'image.small.url') || _get(this, 'image.url') || getImagePath('placeholders/project-card-cover.jpg')
  }
}
