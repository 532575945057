export default [
  {
    key: 'emails_set_up_activation_mails'
  },
  {
    key: 'emails_can_i_change_the_sender_address_of_the_automatic_mails'
  },
  {
    key: 'emails_set_up_transactional_mails'
  }
]