import Section from '@/classes/pages/Section'

export default class Sections extends Array {
  constructor (sections, ...rest) {
    Array.isArray(sections) ? super(...sections.map(section => new Section(section)).filter(section => section.name)) : super(...rest)
  }

  get header () {
    return this.find(section => section.type === 'header')
  }

  get footer () {
    return this.find(section => section.type === 'footer')
  }

  get to_database () {
    return this.map(section => section.to_database)
  }
}
