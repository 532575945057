import i18n from '@/vendors/i18n'
import { metrics_type_options, metrics_sporting_options } from '@/configurations/general/components-default-data'

export default [
  {
    name: 'homepage_beplanet_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionSelect',
        model: 'type',
        value: 'slider',
        settings: {
          options: [
            { value: 'slider', label: i18n.getLocalesTranslations('literal.slider') },
            { value: 'video', label: i18n.getLocalesTranslations('literal.video') }
          ]
        },
        content: {
          label: i18n.getLocalesTranslations('literal.type')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'slides',
        content: {
          btn_label: i18n.getLocalesTranslations('literal.add_image'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.image'),
          wrapper_title: 'title'
        },
        settings: {
          hide_label: true
        },
        conditions: [
          {
            logic_operator: 'and',
            model: 'type',
            value: 'slider',
            comparison_operator: 'eq'
          }
        ],
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 1600, height: 480 }
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'video',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.video'),
        },
        components: [
          {
            path: 'modules/pages/SectionInputUrl',
            model: 'url',
            content: {
              label: i18n.getLocalesTranslations('literal.url')
            }
          }
        ],
        conditions: [
          {
            logic_operator: 'and',
            model: 'type',
            value: 'video',
            comparison_operator: 'eq'
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'partner',
        content: {
          wrapper_title: 'link.text',
          default_wrapper_title: {
            'en-GB': 'Partner',
            'nl-NL': 'Partner',
            'fr-FR': 'Partenaire'
          },
        },
        components: [
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            content: {
              label: i18n.getLocalesTranslations('literal.link')
            }
          },
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.logo')
            },
            model: 'logo',
            settings: {
              crop: false,
              size: { width: 9999, height: 48 }
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'link.show',
                value: true,
                comparison_operator: 'eq'
              }
            ]
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'homepage_beplanet_campaign',
    settings: {
      icon: 'flag',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['heading_h1', 'heading_h2', 'bold', 'italic', 'underline', 'text_color', 'link']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionDivider',
        style: {
          divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'links',
        settings: {
          layout: 'inline',
          hide_label: true
        },
        content: {
          btn_label: i18n.getLocalesTranslations('literal.add_new_link')
        },
        components: [
          {
            path: 'modules/pages/SectionButton',
            model: 'link',
            settings: {
              hide_label: true
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.campaign')
    }
  },
  {
    name: 'homepage_beplanet_stats',
    settings: {
      icon: 'timer',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image',
        settings: {
          crop: true,
          size: { width: 624, height: 479 }
        }
      },
      {
        path: 'modules/pages/SectionDivider',
        style: {
          divider: ['separator','separator__size--full', 'bg__color--vdw', 'margin__t--36']
        }
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
            }
          },
          {
            path: 'modules/pages/SectionSelectForms',
            model: 'procedure_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
            }
          },
          {
            path: 'modules/pages/SectionSelectBenefitings',
            model: 'benefiting_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_beneficiaries')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'metrics',
        content: {
          wrapper_title: 'text',
          btn_label: i18n.getLocalesTranslations('literal.add_metric'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'type',
            value: 'contributors_count',
            settings: {
              options: [...metrics_type_options, { value: 'custom', label: i18n.getLocalesTranslations('literal.custom') }]
            },
            content: {
              label: i18n.getLocalesTranslations('literal.metric')
            }
          },
          {
            path: 'modules/pages/SectionInputDate',
            model: 'date',
            content: {
              label: i18n.getLocalesTranslations('literal.date')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'date',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionSelect',
            model: 'sporting_statistics',
            value: 'total_distance',
            settings: {
              options: metrics_sporting_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sporting_statistics')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'sporting_statistics',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'value',
            content: {
              label: i18n.getLocalesTranslations('literal.value')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'custom',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'text'
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.counter')
    }
  },
  {
    name: 'homepage_beplanet_news',
    settings: {
      icon: 'newsfeed',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'items',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.news'),
          wrapper_title: 'title'
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            content: {
              label: i18n.getLocalesTranslations('literal.image')
            },
            model: 'image',
            settings: {
              crop: true,
              size: { width: 408, height: 235 }
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'title',
            content: {
              label: i18n.getLocalesTranslations('literal.title')
            }
          },
          {
            path: 'modules/pages/SectionInputDate',
            model: 'date',
            content: {
              label: i18n.getLocalesTranslations('literal.date')
            }
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'location',
            content: {
              label: i18n.getLocalesTranslations('literal.location')
            }
          },
          {
            path: 'modules/pages/SectionTextEditor',
            model: 'text',
            settings: {
              configuration: ['bold', 'italic', 'underline']
            },
            content: {
              label: i18n.getLocalesTranslations('literal.text')
            }
          },
          {
            path: 'modules/pages/SectionButton',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.call_to_action')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.news')
    }
  },
  {
    name: 'homepage_beplanet_about',
    settings: {
      icon: 'text_editor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionImageUpload',
        content: {
          label: i18n.getLocalesTranslations('literal.image')
        },
        model: 'image',
        settings: {
          crop: true,
          size: { width: 540, height: 477 }
        }
      },
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionTextEditor',
        model: 'text',
        settings: {
          configuration: ['bold', 'italic', 'underline']
        },
        content: {
          label: i18n.getLocalesTranslations('literal.text')
        }
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.about')
    }
  },
  {
    name: 'homepage_beplanet_partners',
    settings: {
      icon: 'sponsor',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'sponsors',
        settings: {
          hide_label: true
        },
        content: {
          wrapper_title: 'link',
          btn_label: i18n.getLocalesTranslations('literal.add_new_sponsor')
        },
        components: [
          {
            path: 'modules/pages/SectionImageUpload',
            model: 'logo',
            settings: {
              crop: false,
              size: { width: 9999, height: 90 }
            }
          },
          {
            path: 'modules/pages/SectionInputUrl',
            model: 'link'
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.sponsors')
    }
  }
]