import i18n from '@/vendors/i18n'
import { metrics_type_options, metrics_sporting_options, projects_overview_options } from '@/configurations/general/components-default-data'

export default [
  {
    name: 'projects_beplanet_banner',
    settings: {
      icon: 'image',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionInputText',
        model: 'title',
        content: {
          label: i18n.getLocalesTranslations('literal.title')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.banner')
    }
  },
  {
    name: 'projects_beplanet_map',
    settings: {
      icon: 'location',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.campaigns')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionContainer',
        model: 'card',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.content')
        },
        components: [
          {
            path: 'modules/pages/SectionInputText',
            model: 'cta',
            content: {
              label: i18n.getLocalesTranslations('literal.call_to_action')
            }
          }
        ]
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.location')
    }
  },
  {
    name: 'projects_beplanet_stats',
    settings: {
      icon: 'timer',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_campaigns')
            }
          },
          {
            path: 'modules/pages/SectionSelectForms',
            model: 'procedure_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_forms')
            }
          },
          {
            path: 'modules/pages/SectionSelectBenefitings',
            model: 'query.benefiting_id',
            content: {
              label: i18n.getLocalesTranslations('literal.show_metrics_from_these_beneficiaries')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionRepeater',
        model: 'metrics',
        content: {
          wrapper_title: 'text',
          btn_label: i18n.getLocalesTranslations('literal.add_metric'),
          default_wrapper_title: i18n.getLocalesTranslations('literal.choose_a_metric')
        },
        settings: {
          hide_label: true
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'type',
            value: 'contributors_count',
            settings: {
              options: [...metrics_type_options, { value: 'custom', label: i18n.getLocalesTranslations('literal.custom') }]
            },
            content: {
              label: i18n.getLocalesTranslations('literal.metric')
            }
          },
          {
            path: 'modules/pages/SectionInputDate',
            model: 'date',
            content: {
              label: i18n.getLocalesTranslations('literal.date')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'date',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionSelect',
            model: 'sporting_statistics',
            value: 'total_distance',
            settings: {
              options: metrics_sporting_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sporting_statistics')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'sporting_statistics',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'value',
            content: {
              label: i18n.getLocalesTranslations('literal.value')
            },
            conditions: [
              {
                logic_operator: 'and',
                model: 'type',
                value: 'custom',
                comparison_operator: 'eq'
              }
            ]
          },
          {
            path: 'modules/pages/SectionInputText',
            model: 'text'
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.counter')
    }
  },
  {
    name: 'projects_beplanet_actions',
    settings: {
      icon: 'markbook',
      unavailable: true,
      show_delete_option: false,
      show_drag_option: false
    },
    components: [
      {
        path: 'modules/pages/SectionContainer',
        model: 'query',
        content: {
          default_wrapper_title: i18n.getLocalesTranslations('literal.settings')
        },
        components: [
          {
            path: 'modules/pages/SectionSelect',
            model: 'sort',
            value: 'created_at_first',
            settings: {
              options: projects_overview_options
            },
            content: {
              label: i18n.getLocalesTranslations('literal.sort_project_by')
            }
          },
          {
            path: 'modules/pages/SectionInputNumber',
            model: 'per_page',
            value: 4,
            content: {
              label: i18n.getLocalesTranslations('literal.number_of_projects_to_display_at_once')
            }
          },
          {
            path: 'modules/pages/SectionSelectCampaigns',
            model: 'campaign_id',
            content: {
              label: i18n.getLocalesTranslations('literal.campaigns')
            }
          }
        ]
      },
      {
        path: 'modules/pages/SectionButton',
        model: 'cta',
        content: {
          label: i18n.getLocalesTranslations('literal.call_to_action')
        }
      }
    ],
    content: {
      title: i18n.getLocalesTranslations('literal.projects')
    }
  }
]