export default [
  {
    key: 'qrcodes_introduction_to_qr_codes'
  },
  {
    key: 'qrcodes_what_kind_of_qr_codes_are_there'
  },
  {
    key: 'qrcodes_create_qr_codes_with_koalect_scan'
  }
]
