import i18n from '@/vendors/i18n'

export default {
  name: 'custom_footer_uzafoundation',
  settings: {
    unavailable: true
  },
  components: [
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_1',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.navigation')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'links',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_2',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.contact')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'contact',
          settings: {
            hide_label: true
          },
          content: {
            wrapper_title: 'text',
            default_wrapper_title: i18n.getLocalesTranslations('literal.contact')
          },
          components: [
            {
              path: 'modules/pages/SectionSelect',
              model: 'type',
              value: 'text',
              settings: {
                options: [
                  { value: 'text', label: i18n.getLocalesTranslations('literal.text') },
                  { value: 'email', label: i18n.getLocalesTranslations('literal.email') },
                  { value: 'phone', label: i18n.getLocalesTranslations('literal.phone') }
                ]
              },
              content: {
                label: i18n.getLocalesTranslations('literal.type')
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'prefix',
              content: {
                label: {
                  'fr-FR': 'Préfixe',
                  'nl-NL': 'Voorvoegsel',
                  'en-GB': 'Prefix'
                }
              }
            },
            {
              path: 'modules/pages/SectionInputText',
              model: 'text'
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_3',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.address')
      },
      components: [
        {
          path: 'modules/pages/SectionInputText',
          model: 'title',
          content: {
            label: i18n.getLocalesTranslations('literal.title')
          }
        },
        {
          path: 'modules/pages/SectionRepeater',
          model: 'address',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          components: [
            {
              path: 'modules/pages/SectionInputText',
              model: 'text',
              settings: {
                hide_label: true
              }
            }
          ]
        }
      ]
    },
    {
      path: 'modules/pages/SectionContainer',
      model: 'column_4',
      content: {
        default_wrapper_title: i18n.getLocalesTranslations('literal.footer')
      },
      components: [
        {
          path: 'modules/pages/SectionRepeater',
          model: 'links',
          settings: {
            hide_label: true,
            layout: 'inline'
          },
          content: {
            btn_label: i18n.getLocalesTranslations('literal.add_new_link')
          },
          components: [
            {
              path: 'modules/pages/SectionButton',
              model: 'link',
              settings: {
                hide_label: true
              }
            }
          ]
        },
        {
          path: 'modules/pages/SectionDivider',
          style: {
            divider: ['separator', 'separator__size--full', 'bg__color--vdw']
          }
        },
        {
          path: 'modules/pages/SectionTitle',
          content: {
            label: i18n.getLocalesTranslations('literal.social_networks')
          }
        },
        {
          path: 'modules/pages/SectionNetworks'
        }
      ]
    }
  ]
}
