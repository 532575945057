import i18n from '@/vendors/i18n'

export default {
  name: 'media+text',
  settings: {
    icon: 'layout'
  },
  components: [
    {
      path: 'molecules/ModuleInputTextEditor',
      model: 'attributes.text',
      content: {
        label: i18n.getLocalesTranslations('literal.text')
      }
    },
    {
      path: 'molecules/ModuleInputLink',
      model: 'attributes.button',
      content: {
        label: i18n.getLocalesTranslations('literal.button')
      }
    },
    {
      path: 'molecules/ModuleInputRadioSlide',
      model: 'attributes.media.type',
      settings: {
        options: [{
          label: i18n.t('literal.image'),
          value: 'image'
        },
        {
          label: i18n.t('literal.video'),
          value: 'video'
        }]
      }
    },
    {
      path: 'molecules/ModuleImageUpload',
      model: 'attributes.media.image_url',
      settings: {
        size: { width: 700, height: 466 }
      },
      content: {
        label: i18n.getLocalesTranslations('literal.image')
      },
      conditions: [
        {
          logic_operator: 'and',
          model: 'media.type',
          value: 'image',
          comparison_operator: 'eq'
        }
      ]
    },
    {
      path: 'molecules/ModuleInputUrl',
      model: 'attributes.media.video_url',
      content: {
        label: i18n.getLocalesTranslations('literal.video_link')
      },
      validations: ['url', 'validVideoUrl'],
      conditions: [
        {
          logic_operator: 'and',
          model: 'media.type',
          value: 'video',
          comparison_operator: 'eq'
        }
      ]
    }
  ],
  style: {
    background: {
      image: i18n.getLocalesTranslations(),
      color: null,
      type: 'color'
    },
    text: {
      color: null
    },
    button: {
      color: null,
      background: null
    },
    layout: 'normal'
  },
  content: {
    title: i18n.getLocalesTranslations('$page_section.media_text')
  }
}
