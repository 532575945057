import page_sections from '@/configurations/pages'
import { get as _get } from 'lodash'
import { guid } from '@/utils/utils'
import Options from '@/classes/pages/Options'

export default class Section {
  constructor ({ name = null, type = null, style = {}, options, attributes = {} } = {}) {
    this.id = name + guid(2)
    this.name = name
    this.type = type
    this.style = style
    this.options = new Options(options)
    this.attributes = attributes
  }

  get config () {
    return page_sections.find(section => section.name === this.name)
  }

  get settings () {
    return this.config?.settings || {}
  }

  get content () {
    return this.config?.content || {}
  }

  get components () {
    return _get(this.config, 'components', []).filter(component => component.path)
  }

  get is_not_editable () {
    return this.options.not_editable || false
  }

  get show_delete_option () {
    return this.settings.show_delete_option || true
  }

  get show_visible_option () {
    return this.settings.show_visible_option || true
  }

  get show_drag_option () {
    return this.settings.show_drag_option || true
  }

  get show_style_option () {
    return !!Object.values(this.style).length
  }

  get icon () {
    return this.settings.icon
  }

  get title () {
    return this.content.title
  }

  get to_database () {
    return {
      name: this.name,
      style: this.style,
      type: this.type,
      options: this.options,
      attributes: this.attributes
    }
  }
}
