const Campaigns = () => import(/* webpackChunkName: 'Campaigns' */'@/views/campaigns/ViewCampaigns')
const NewCampaign = () => import(/* webpackChunkName: 'NewCampaign' */'@/views/campaigns/ViewNewCampaign')
const Campaign = () => import(/* webpackChunkName: 'Campaign' */'@/views/campaigns/ViewCampaign')
const CampaignSettings = () => import(/* webpackChunkName: 'CampaignSettings' */'@/views/campaigns/ViewCampaignSettings')
const CampaignProcedures = () => import(/* webpackChunkName: 'CampaignProcedures' */'@/views/campaigns/ViewCampaignProcedures')
const CampaignEmails = () => import(/* webpackChunkName: 'CampaignEmails' */'@/views/campaigns/ViewCampaignEmails')
const CampaignProducts = () => import(/* webpackChunkName: 'CampaignEmails' */'@/views/campaigns/ViewCampaignProducts')
const CampaignProjectPage = () => import(/* webpackChunkName: 'CampaignProjectPage' */'@/views/campaigns/ViewCampaignProjectPage')
const CampaignNewQrcode = () => import(/* webpackChunkName: 'CampaignNewQrcode' */'@/views/campaigns/ViewCampaignNewQrcode')

const routes = [
  {
    path: 'campaigns',
    name: 'Campaigns',
    component: Campaigns,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' }
      ],
      requireFeature: ['campaigns'],
      faq: ['campaigns_how_to_get_started']
    }
  },
  {
    path: 'campaigns/new',
    name: 'NewCampaign',
    component: NewCampaign,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { label: 'literal.new_campaign', routeName: 'NewCampaign' }
      ]
    }
  },
  {
    path: 'campaigns/:campaignSlug',
    name: 'Campaign',
    component: Campaign,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { routeName: 'Campaign' }
      ],
      faq: ['campaigns_how_to_get_started']
    }
  },
  {
    path: 'campaigns/:campaignSlug/settings',
    name: 'CampaignSettings',
    component: CampaignSettings,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { routeName: 'Campaign' },
        { label: 'literal.campaign_settings', routeName: 'CampaignSettings' }
      ],
      faq: [
        'campaigns_how_to_manage_your_campaign_settings',
        'campaigns_how_to_enable_strava_in_your_campaigns'
      ]
    }
  },
  {
    path: 'campaigns/:campaignSlug/procedures',
    name: 'CampaignProcedures',
    component: CampaignProcedures,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' }
      ],
      faq: [
        'campaigns_what_are_the_forms',
        'campaigns_how_can_i_build_a_form'
      ]
    }
  },
  {
    path: 'campaigns/:campaignSlug/procedures/new-qrcode',
    name: 'CampaignNewQrcode',
    component: CampaignNewQrcode,
    props: true,
    meta: {
      requiresManagerRole: true,
      requireFeature: ['twikey'],
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { routeName: 'Campaign' },
        { label: 'literal.forms', routeName: 'CampaignProcedures' },
        { label: 'literal.add_qr_code_form', routeName: 'CampaignNewQrcode' }
      ],
      navigation: {
        back: 'CampaignProcedures'
      }
    }
  },
  {
    path: 'campaigns/:campaignSlug/emails',
    name: 'CampaignEmails',
    redirect: { name: 'CampaignEmailsTransactional' }
  },
  {
    path: 'campaigns/:campaignSlug/emails/transactional',
    name: 'CampaignEmailsTransactional',
    component: CampaignEmails,
    props: true,
    meta: {
      type: 'transactional',
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { routeName: 'Campaign' },
        { label: 'literal.transactional_emails', routeName: 'CampaignEmailsTransactional' }
      ],
      faq: [
        'campaigns_set_up_automatic_mails_for_your_campaign',
        'emails_set_up_transactional_mails',
        'emails_can_i_change_the_sender_address_of_the_automatic_mails'
      ]
    }
  },
  {
    path: 'campaigns/:campaignSlug/emails/activation',
    name: 'CampaignEmailsActivation',
    component: CampaignEmails,
    props: true,
    meta: {
      type: 'activation',
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { routeName: 'Campaign' },
        { label: 'literal.activation_emails', routeName: 'CampaignEmailsActivation' }
      ],
      faq: [
        'campaigns_set_up_automatic_mails_for_your_campaign',
        'emails_set_up_activation_mails',
        'emails_can_i_change_the_sender_address_of_the_automatic_mails',
      ]
    }
  },
  {
    path: 'campaigns/:campaignSlug/products',
    name: 'CampaignProducts',
    component: CampaignProducts,
    props: true,
    redirect: { name: 'CampaignProductsCampaign' },
    meta: {
      sub_navigation: [
        { label: 'literal.products_from_campaign', routeName: 'CampaignProductsCampaign' },
        { label: 'literal.products_created_by_projects', routeName: 'CampaignProductsProjects' }
      ]
    },
    children: [
      {
        path: '',
        name: 'CampaignProductsCampaign',
        component: CampaignProducts,
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { label: 'literal.campaigns', routeName: 'Campaigns' },
            { routeName: 'Campaign' },
            { label: 'literal.products', routeName: 'CampaignProducts' }
          ],
          navigation: {
            product: 'CampaignEditProduct'
          },
          faq: [
            'campaigns_what_are_products',
            'campaigns_how_can_i_use_products_in_my_campaign'
          ]
        }
      },
      {
        path: 'projects',
        name: 'CampaignProductsProjects',
        component: CampaignProducts,
        meta: {
          requiresAuth: true,
          targetType: 'Project',
          breadcrumb: [
            { label: 'literal.campaigns', routeName: 'Campaigns' },
            { routeName: 'Campaign' },
            { label: 'literal.products', routeName: 'CampaignProducts' }
          ],
          navigation: {
            product: 'CampaignEditProduct'
          },
          faq: [
            'campaigns_what_are_products',
            'campaigns_how_can_i_use_products_in_my_campaign'
          ]
        }
      }
    ]
  },
  {
    path: 'campaigns/:campaignSlug/project-page',
    name: 'CampaignProjectPage',
    component: CampaignProjectPage,
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { label: 'literal.campaigns', routeName: 'Campaigns' },
        { routeName: 'Campaign' },
        { label: 'literal.project_page', routeName: 'CampaignProjectPage' }
      ],
      faq: [
        'campaigns_how_can_i_setup_the_project_page',
        'campaigns_what_are_the_qr_codes_with_payconiq',
        'campaigns_how_to_use_the_payconiq_qr_codes',
        'campaigns_introduction_to_templates',
        'campaigns_how_to_create_a_template',
        'campaigns_how_do_the_new_templates_affect_existing_campaigns_and_projects',
        'campaigns_what_is_project_validation',
        'campaigns_does_my_campaign_need_project_validation',
        'campaigns_how_to_enable_project_validation'
      ]
    }
  }
]

export default routes
